import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PagerService } from './pagerservice';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class Util {
  constructor(private router: Router,
    private pagerService: PagerService) { }

  IsNotEmpty(data: any, eq: any = '', neq: any = '') {
    if (data != null && data !== undefined && data !== '') {
      if (eq != null && eq !== undefined && eq !== '') {
        return (data.toString().toLowerCase() === eq.toString().toLowerCase());
      }
      else if (neq != null && neq !== undefined && neq !== '') {
        return (data.toString().toLowerCase() !== neq.toString().toLowerCase());
      }
    }
    return (data != null && data !== undefined && data !== '');
  }

  IsNotNull(data: any) {
    return (data != null);
  }

  IsNotUndefined(data: any) {
    return (data != null && data !== undefined);
  }

  IsLength(data: any) {
    return (this.IsNotNull(data) && Array.isArray(data) && data.length !== 0);
  }

  IsNoLength(data: any) {
    return ((!this.IsNotNull(data)) || (!Array.isArray(data)) ||
      (this.IsNotNull(data) && Array.isArray(data) && data.length === 0));
  }

  IsSuccess(data: any) {
    return (this.IsNotUndefined(data) && this.IsNotUndefined(data.Success) && data.Success === true);
  }

  getCount(data: any, columnName: any, columnValue: any, isAllCount = false) {
    let count = 0
    if (this.IsLength(data) && this.IsNotEmpty(columnName) && this.IsNotEmpty(columnValue)) {
      const lstData = data.filter((item: any) => {
        return (this.IsNotEmpty(item[columnName]) && (item[columnName] === columnValue)) ? true : false;
      });
      count = (this.IsLength(lstData)) ? lstData.length : 0;
    }
    else {
      count = (this.IsLength(data) && isAllCount) ? data.length : 0
    }
    return count;
  }

  convertLowerCase(data: any) {
    if (this.IsNotEmpty(data)) {
      data = data.toLowerCase();
    }
    return data;
  }

  pad(n: any, width: any, z: any) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  pad2(data: number) {
    return (data < 10 ? '0' : '') + data;
  }

  pad3(data: number) {
    return (data < 10 ? '00' : (data < 100 ? '0' : '')) + data;
  }

  pad4(data: number) {
    return (data < 10 ? '000' : (data < 100 ? '00' : (data < 1000 ? '0' : ''))) + data;
  }

  getCurrentDateToDB() {
    const dateObj = new Date();
    return this.formatDate(dateObj);
  }

  getCurrentTime() {
    const dateObj = new Date();
    const h = dateObj.getHours();
    const m = dateObj.getMinutes();
    return this.pad2(h) + ':' + this.pad2(m);
  }

  getYesterdayDateToDB() {
    const datePrevObj = new Date();
    datePrevObj.setDate(datePrevObj.getDate() - 1);
    return this.formatDate(datePrevObj);
  }

  getTime(dateObj: Date) {
    const time = this.pad2(dateObj.getHours()) + ':' + this.pad2(dateObj.getMinutes());
    return time;
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  formatDate(date: Date, delimitator: any = '-', format = '') {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    month = this.pad2(parseInt(month, 10));
    day = this.pad2(parseInt(day, 10));
    if (this.IsNotEmpty(format) && format === 'dmy') {
      return [day, month, year].join(delimitator);
    }
    return [year, month, day].join(delimitator);
  }

  formateWeeklySalesDate(date: Date) {
    const arrDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const arrMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    month = this.pad2(parseInt(month, 10));
    day = this.pad2(parseInt(day, 10));
    return arrDay[d.getDay()] + '(' + arrMonth[d.getMonth()] + ' ' + day + ')';
  }

  unique(inputArr: any) {
    var arr = [];
    if (this.IsLength(inputArr)) {
      for (var i = 0; i < inputArr.length; i++) {
        const person = arr.find(element =>
          JSON.stringify(element).toLowerCase() ===
          JSON.stringify(inputArr[i]).toLowerCase());
        if (!this.IsNotEmpty(person)) {
          arr.push(inputArr[i]);
        }
      }
    }
    return arr;
  }

  contains(masterArry: any, inputArr: any) {
    if (this.IsLength(masterArry) && this.IsNotNull(inputArr)) {
      for (var i = 0; i < masterArry.length; i++) {
        if (masterArry[i] === inputArr) {
          return true;
        }
      }
    }
    return false;
  }

  shortName(fullName) {
    let shortName = '';
    try {
      if (this.IsNotEmpty(fullName)) {
        let matches = (this.IsNotEmpty(fullName)) ? fullName.match(/\b(\w)/g) : [''];
        if (matches == null || matches == '') {
          matches = (this.IsNotEmpty(fullName)) ? fullName.match(/\b(\w)/g) : [''];
        }
        shortName = (this.IsLength(matches)) ? matches.join('') : ''
      }
    } catch (error) {

    }
    return shortName;
  }

  isAuthenticated() {
    let isAuthenticated = false;
    // const userDetails = this.getUserDetails();
    try {
      // if (this.IsNotNull(userDetails)) {
      //   isAuthenticated = userDetails.isAuthenticated;
      // }
    } catch (error) {

    }
    return true;
  }

  setUserDetails(response: any) {
    try {
      if (this.IsNotEmpty(response)) {
        const [userDetails] = response;
        if (this.IsNotEmpty(userDetails)) {
          localStorage.setItem('userDetails', JSON.stringify(userDetails));
        }
      }

    } catch (error) {

    }
  }

  getUserDetails() {
    let userDetails;
    try {

      userDetails = {
        isAuthenticated: false,
        userEmailAddress: '',
        userId: '',
        userName: '',
        userRoleId: '',
        userRoleName: '',
        userBanner: [],
        userGroup: [],
        userStore: [],
      }

      if (this.IsNotEmpty(localStorage.getItem('userDetails'))) {
        const userData = JSON.parse(localStorage.getItem('userDetails'))
        if (this.IsNotEmpty(userData)) {

          if (this.IsNotEmpty(userData.UserRoles) &&
            this.IsNotNull(userData.UserRoles.split(":"))) {
            if (userData.UserRoles.split(":").length >= 1) {
              userDetails.userRoleId = userData.UserRoles.split(":")[0]
            }
            if (userData.UserRoles.split(":").length >= 2) {
              userDetails.userRoleName = userData.UserRoles.split(":")[1]
            }
          }

          if (this.IsNotEmpty(userData.IsAuthenticated)) {
            userDetails.isAuthenticated = (parseInt(userData.IsAuthenticated, 10) === 1) ? true : false;
          }

          if (this.IsNotEmpty(userData.UserEmailAddress)) {
            userDetails.userEmailAddress = userData.UserEmailAddress
          }

          if (this.IsNotEmpty(userData.UserID)) {
            userDetails.userId = userData.UserID
          }

          if (this.IsNotEmpty(userData.FirstUserName)) {
            userDetails.userName = userData.FirstUserName
          }

          if (this.IsNotEmpty(userData.UserRoles)) {
            userDetails.userRoles = userData.UserRoles
          }

          if (this.IsNotEmpty(userData.UserBanners)) {
            if (this.IsLength(userData.UserBanners.split(','))) {
              let lstBannerData = [];
              for (const bannerData of userData.UserBanners.split(',')) {
                if (this.IsLength(bannerData.split(':'))) {
                  lstBannerData.push({
                    BannerID: bannerData.split(':')[0],
                    BannerName: bannerData.split(':')[1],
                  })
                }
              }
              if (this.IsLength(lstBannerData)) {
                userDetails.userBanner = lstBannerData;
              }
            }
          }


          if (this.IsNotEmpty(userData.UserGroup)) {
            if (this.IsLength(userData.UserGroup.split(','))) {
              let lstGroupData = [];
              for (const groupData of userData.UserGroup.split(',')) {
                if (this.IsLength(groupData.split(':'))) {
                  lstGroupData.push({
                    GroupId: groupData.split(':')[0],
                    GroupName: groupData.split(':')[1],
                  })
                }
              }
              if (this.IsLength(lstGroupData)) {
                userDetails.userGroup = lstGroupData;
              }
            }
          }

          if (this.IsNotEmpty(userData.UserStore)) {
            if (this.IsLength(userData.UserStore.split(','))) {
              let lstStoreData = [];
              for (const storeData of userData.UserStore.split(',')) {
                if (this.IsLength(storeData.split(':'))) {
                  lstStoreData.push({
                    StoreId: storeData.split(':')[0],
                    StoreName: storeData.split(':')[1],
                  })
                }
              }
              if (this.IsLength(lstStoreData)) {
                userDetails.userStore = lstStoreData;
              }
            }
          }
        }
      }
    }
    catch (error) {

    }
    return userDetails;
  }

  getToken() {
    let token = ''
    try {
      if (this.IsNotEmpty(localStorage.getItem('token'))) {
        let token = localStorage.getItem('token')
      }
      else {
        localStorage.setItem('token', token);
      }
    } catch (error) {

    }
    return token;
  }

  setToken(token: any) {
    try {
      if (this.IsNotNull(token)) {
        localStorage.setItem('token', token);
      }
    } catch (error) {

    }
  }

  setCustomerName(cutomerName: any) {
    try {
      if (this.IsNotNull(cutomerName)) {
        localStorage.setItem('cutomerName', cutomerName);
      }
    } catch (error) {

    }
  }

  getCustomerName() {
    let customerName = ''
    try {
      if (this.IsNotEmpty(localStorage.getItem('cutomerName'))) {
        let customerName = localStorage.getItem('cutomerName')
      }
      else {
        localStorage.setItem('cutomerName', customerName);
      }
    } catch (error) {

    }
    return customerName;
  }


  setUserAccessDetails(UserAccessDetails: any) {
    try {
      if (this.IsNotNull(UserAccessDetails)) {
        localStorage.setItem('UserAccessDetails', JSON.stringify(UserAccessDetails));
      }
    } catch (error) {

    }
  }

  getUserAccessDetails() {
    let UserAccessDetails = {
      "account_guid": "",
      "login_guid": "",
      "new_login_guid": "",
      "loyalty_membership_id": "",
      "loyalty_pk": "",
      "email_guid": "",
      "coupon_guid": ""
    }
    try {
      if (this.IsNotEmpty(localStorage.getItem('UserAccessDetails'))) {
        UserAccessDetails = JSON.parse(localStorage.getItem('UserAccessDetails'))
      }
      else {
        localStorage.setItem('UserAccessDetails', JSON.stringify(UserAccessDetails));
      }
    } catch (error) {

    }
    return UserAccessDetails;
  }

  userId() {
    let userId = '';
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userId = userDetails.userId;
      }
    } catch (error) {

    }
    return userId;
  }

  userName() {
    let userName = '';
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userName = userDetails.userName;
      }
    } catch (error) {

    }
    return userName;
  }

  userShortName() {
    let userShortName = '';
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails) && userDetails.userName) {
        let matches = (userDetails.userName) ? userDetails.userName.match(/\b(\w)/g) : [''];
        if (matches == null || matches == '') {
          matches = (userDetails.userName) ? userDetails.userName.match(/\b(\w)/g) : [''];
        }
        userShortName = (this.IsLength(matches)) ?
          (matches.length === 1) ? userDetails.userName.substring(0, 2) : matches.join('') : ''

        if (this.IsNotEmpty(userShortName)) {
          userShortName = userShortName.toUpperCase();
        }
      }
    } catch (error) {

    }
    return userShortName;
  }

  userEmailAddress() {
    let userEmailAddress = '';
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userEmailAddress = userDetails.userEmailAddress;
      }
    } catch (error) {

    }
    return userEmailAddress;
  }

  userRoleId() {
    let userRoleId = '';
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userRoleId = userDetails.userRoleId;
      }
    } catch (error) {

    }
    return userRoleId;
  }

  userRoleName() {
    let userRoleName = '';
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userRoleName = userDetails.userRoleName;
      }
    } catch (error) {

    }
    return userRoleName;
  }

  userBanner() {
    let userBanner = [];
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userBanner = userDetails.userBanner;
      }
    } catch (error) {

    }
    return userBanner;
  }

  userGroup() {
    let userGroup = [];
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userGroup = userDetails.userGroup;
      }
    } catch (error) {

    }
    return userGroup;
  }

  userStore() {
    let userStore = [];
    const userDetails = this.getUserDetails();
    try {
      if (this.IsNotNull(userDetails)) {
        userStore = userDetails.userStore;
      }
    } catch (error) {

    }
    return userStore;
  }


  autoNumber(value: any = '', prefix: any = '', padding: any = 4) {
    if (this.IsNotEmpty(value)) {
      if (this.IsNotEmpty(prefix)) {
        return prefix + '-' + this.pad(parseInt(value, 10), padding, 0)
      }
      else {
        return this.pad(parseInt(value, 10), padding, 0)
      }
    }
    else {
      return value;
    }
  }


  isSessionExist() {
    let isExist = false;
    const userDetails = this.getUserDetails();
    isExist = (this.IsNotNull(userDetails) &&
      this.IsNotEmpty(userDetails.userId) && userDetails.userId !== '0') ? true : false
    return isExist;
  }

  validateSession() {
    // if (!this.isSessionExist()) {
    //   localStorage.clear()
    //   this.router.navigate(['/login']);
    // }
  }

  applyFilter(value: string, lstColumnData: any, lstFilterData: any, lstMasterData: any) {
    if (this.IsNotEmpty(value) && this.IsLength(lstMasterData) && this.IsLength(lstColumnData)) {
      lstFilterData =
        lstMasterData.filter((item) => {
          const lstData = lstColumnData.
            filter((x: any) => (x.isSearch === true)).
            filter(column => {
              let columnValue = item[column.Name];
              columnValue = (this.IsNotNull(column.isAuto) && column.isAuto === true &&
                this.IsNotEmpty(column.Prefix) && this.IsNotEmpty(column.Padding)) ?
                this.autoNumber(columnValue, column.Prefix, column.Padding) : columnValue;
              return (this.IsNotNull(column) &&
                this.IsNotEmpty(column.Name) &&
                this.IsNotNull(columnValue) &&
                columnValue.toString().toLowerCase().indexOf(value) !== -1);
            });
          return (this.IsLength(lstData)) ? true : false
        });
    }
    else {
      lstFilterData = lstMasterData;
    }
    return lstFilterData;
  }

  applySort(column: string, lstFilterData: any, lstMasterData: any, asc = false) {
    if (this.IsNotEmpty(column) && this.IsLength(lstMasterData)) {
      lstFilterData = lstMasterData.sort((a, b) => {
        if (asc) {
          return (b['' + column + ''] > a['' + column + ''] ? -1 : 1);
        } else {
          return (a['' + column + ''] > b['' + column + ''] ? -1 : 1);
        }
      });
    }
    return lstFilterData;
  }

  setPager(lstMasterData: any, pager: any, page: any = 1, pageSize: any = 5) {
    let pagerData = null;
    if (page < 1 || (this.IsNotNull(pager) && page > pager.totalPages)) {
      return;
    }
    if (this.IsLength(lstMasterData)) {
      pagerData = this.pagerService.getPager(lstMasterData.length, page, pageSize);
    }
    return pagerData;
  }

  setStaticPager(totalItems: any, pager: any, page: any = 1, pageSize: any = 5) {
    let pagerData = null;
    if (page < 1 || (this.IsNotNull(pager) && page > pager.totalPages)) {
      return;
    }
    pagerData = this.pagerService.getPager(totalItems, page, pageSize);
    return pagerData;
  }

  applyPagination(lstMasterData: any, pager: any, page: any = 1, pageSize: any = 5) {
    if (page < 1 || (this.IsNotNull(pager) && page > pager.totalPages)) {
      return;
    }
    if (!this.IsNotNull(lstMasterData)) {
      lstMasterData = [];
    }
    return (this.IsLength(lstMasterData)) ? lstMasterData.slice(pager.startIndex, pager.endIndex + 1) : lstMasterData
  }

  setlocation(location) {
    try {
      if (this.IsNotEmpty(location) && this.IsNotEmpty(location.origin)) {
        localStorage.setItem('siteLocation', location.origin);
      }
    } catch (error) {

    }
  }

  getlocation() {
    let siteLocation = 'http://localhost:4205';
    try {
      if (this.IsNotEmpty(localStorage.getItem('siteLocation'))) {
        siteLocation = localStorage.getItem('siteLocation')
      }
      else {
        localStorage.setItem('siteLocation', siteLocation);
      }
    } catch (error) {

    }
    return siteLocation;
  }

  isDev() {
    let isDev = false;
    const siteLocation = this.getlocation();
    try {      
        let getDomain = siteLocation;
        if (siteLocation.indexOf('http') >= 0) {
          getDomain = siteLocation.split('//')[1];
        }
        if (this.IsNotEmpty(getDomain) && (getDomain.toLowerCase() == environment.UATSiteURL.toLowerCase() || getDomain.toLowerCase().indexOf("dev") >= 0)) {
          isDev = true;
        }
    }catch (error) {
    }
    return isDev;
  }

  isUAT() {
    let isUAT = false;
    const siteLocation = this.getlocation();
    try {      
        let getDomain = siteLocation;
        if (siteLocation.indexOf('http') >= 0) {
          getDomain = siteLocation.split('//')[1];
        }
        if (this.IsNotEmpty(getDomain) && (getDomain.toLowerCase() == environment.UATSiteURL.toLowerCase() || getDomain.toLowerCase().indexOf("dev") >= 0)) {
          isUAT = true;
        }
    }catch (error) {
    }
    return isUAT;
  }

  isProd() {
    let isProd = false;
    const siteLocation = this.getlocation();
    try {
      let getDomain = siteLocation;
      if (siteLocation.indexOf('http') >= 0) {
        getDomain = siteLocation.split('//')[1];
      }
      if (this.IsNotEmpty(getDomain) && getDomain.toLowerCase() == environment.ProdSiteURL.toLowerCase()) {
        isProd = true;
      }
    } catch (error) {

    }
    return isProd;
  }

  toDateOject(dateStr: any, delimitator: any = '-', formate: any = 'dmy') {
    if (this.IsNotEmpty(dateStr)) {
      if (formate === 'dmy') {
        const [year, month, day] = dateStr.split(delimitator)
        return new Date(year, month - 1, day)
      }
      else if (formate === 'ymd') {
        const [year, month, day] = dateStr.split(delimitator)
        return new Date(year, month - 1, day)
      }
    }
    return null
  }

  addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  toPercentage(itemValue: any, total: any) {
    if (!isNaN(itemValue) && !isNaN(total)) {
      return (itemValue * 100) / total;
    }
    return 0;
  }

  removeDuplicateArray(arr: any = []) {
    let result = []
    if (this.IsLength(arr)) {
      result = arr.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          JSON.stringify(t).toLowerCase() === JSON.stringify(thing).toLowerCase()
        )));
    }
    return result;
  }

  getUniqueListOfColumn(lsit: any, name: any) {
    let result = []
    if (this.IsLength(lsit) && this.IsNotEmpty(name)) {
      result = lsit.map(item => item[name])
        .filter((value, index, self) => self.indexOf(value) === index)
    }
    return result;
  }

  enableCheckboxByStatus(lstData: any, userRoleId: any, StatusId: any): any {
    let isEnable = false;
    if (this.IsLength(lstData) &&
        this.IsNotEmpty(userRoleId) &&
        this.IsNotEmpty(StatusId)) {
        if (StatusId !== '0') {
            switch (userRoleId.toString()) {
              case "1":
                isEnable = (StatusId.toString() === '1' || StatusId.toString() === '2' ) ? true : false;
                break;

                case "3":
                    isEnable = (StatusId.toString() === '1') ? true : false;
                    break;
                case "2":
                    isEnable = (StatusId.toString() === '2') ? true : false;
                    break;
                    case "6":
                      // Add your custom logic for userRoleId = 6
                      isEnable = (StatusId.toString() === '2') ? true : false;
                      break;
                  default:
                    isEnable = false
                    break;
            }
        }
        else {
            switch (userRoleId.toString()) {

                case "1":
                isEnable = lstData.
                some((x: any) => x.CorrectionStatusId.toString() === '1' ||  x.CorrectionStatusId === 2)
                break;

                case "3":
                    isEnable = lstData.
                        some((x: any) => x.CorrectionStatusId.toString() === '1')
                    break;
                case "2":
                    isEnable = lstData.
                        some((x: any) => x.CorrectionStatusId.toString() === '2')
                    break;

                case "6":
                  isEnable = lstData.
                  some((x: any) => x.CorrectionStatusId.toString() === '2')
                  break;

                default:
                    isEnable = false
                    break;
            }
        }
    }
    return isEnable
}
}
